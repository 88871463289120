export function getSearchStringFromHash(hash: string): string {
  if (hash === undefined || hash === '' || hash.length < 8)
    return ''

  const split = hash.split('?search=')
  if (split === undefined || split?.length < 2)
    return ''

  return split[1]
}

export function getMitarbeiterStringFromHash(hash: string): string {
  if (hash === undefined || hash === '' || hash.length < 13)
    return ''

  const split = hash.split('?mitarbeiter=')
  if (split === undefined || split?.length < 2)
    return ''

  return split[1]
}

export function getCookie(cookieKey) {
  const cookies = parseCookie(document.cookie)
  const obj = cookies !== null ? cookies[cookieKey] : null
  try {
    return JSON.parse(obj)
  }
  catch (e) {
    return obj
  }
}

export function parseCookie(str) {
  if (!str)
    return null

  return str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
      return acc
    }, {})
}
