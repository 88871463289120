import { Roles } from '~/config/constants'

export function isValidUrl(urlString: string): boolean {
  if (urlString?.length > 200)
    return false

  const urlPattern = new RegExp('^(https?:\\/\\/)?' // validate protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // validate domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // validate OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // validate port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // validate query string
    + '(\\#[-a-z\\d_]*)?$', 'i') // validate fragment locator
  return !!urlPattern.test(urlString)
}

export function isValidRole(roleString: string): boolean {
  return Object.values(Roles).includes(roleString)
}
