<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps({
  error: Object as () => NuxtError,
})
const config = useRuntimeConfig()
const environment = config.public.env
</script>

<template>
  <div class="flex h-screen flex-col items-stretch justify-stretch">
    <header
      class="z-30 flex min-h-[4rem] items-center gap-2 bg-white px-4 py-2 shadow-default"
    >
      <div class="mr-auto block">
        <NuxtIcon
          v-if="environment.includes('dev')"
          name="zeus-admin-logo-dev"
          class="logo"
          filled
        />
        <NuxtIcon
          v-else-if="environment.includes('test')"
          name="zeus-admin-logo-test"
          class="logo"
          filled
        />
        <NuxtIcon
          v-else-if="environment.includes('stage')"
          name="zeus-admin-logo-stage"
          class="logo"
          filled
        />
        <NuxtIcon
          v-else
          name="zeus-admin-logo"
          class="logo"
          filled
        />
      </div>
    </header>
    <main class="flex min-h-0 min-w-0 grow">
      <div class="px-12 pt-8">
        <h2 class="text-4xl font-extrabold">
          {{ error.statusCode }}
        </h2>
        <p>{{ error.statusMessage }}</p>
        {{ error }}
      </div>
    </main>
  </div>
</template>

<style scoped>
.logo svg {
  height: 24px;
  width: 100%;
}
</style>
