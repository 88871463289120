export const BASE_API = import.meta.env.VITE_BASE_API
export const DEV = 'dev'
export const TEST = 'test'
export const STAGE = 'stage'

export const ZEUS_TOKEN = 'zeus-token'
export const ZEUS_USER_INFO = 'zeus-user-info'

export const ADMIN = 'admin'
export const DELETE_OK = 'DELETE_OK'
export const DELETE_FAILED = 'DELETE_FAILED'
export const FETCH_OK = 'FETCH_OK'
export const POST_OK = 'POST_OK'
export const FETCH_ERROR = 'FETCH_ERROR'
export const POST_ERROR = 'POST_ERROR'
export const REVERT_OK = 'REVERT_OK'
export const DELETE_TOPIC_CONTAINERS_NOT_IMPLEMENTED = 'DELETE_TOPIC_CONTAINERS_NOT_IMPLEMENTED'
export const OK = 'OK'
export const ERROR = 'ERROR'
export const PUT = 'PUT'

export const TOGGLE_HISTORY_EMIT = 'toggleHistory'
export const SET_CURRENT_TOPIC_CONTAINER_ID_AND_OPEN_HISTORY_EMIT = 'setCurrentTopicContainerIdAndOpenHistory'
export const SET_CURRENT_TOPIC_ID_AND_OPEN_HISTORY_EMIT = 'setCurrentTopicIdAndOpenHistory'

export const POST = 'POST'
export const GET = 'GET'
export const BEARER = 'Bearer'
export const DELETE = 'DELETE'

export enum RubricInfoCode {
  ID_MUST_BE_SET = 'ID_MUST_BE_SET',
  UNSAVED_CHANGES = 'UNSAVED_CHANGES',
}

export enum RubricErrorCode {
  REVERT_ERROR = 'REVERT_ERROR',
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  MAPPING_ERROR = 'MAPPING_ERROR',
}

export enum TopicContainerNewRowEmits {
  REFRESH_TABLE = 'refreshTable',
  EMIT_TABLE = 'emitTable',
  SORT_TABLE = 'sortTable',
}

export enum TopicContainerTableCellEditableEmits {
  IS_EMPTY = 'isEmpty',
  BLUR = 'blur',
  FOCUS = 'focus',
}

export enum TopicContainerErrorCode {
  REVERT_ERROR = 'REVERT_ERROR',
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  MAPPING_ERROR = 'MAPPING_ERROR',
  PUT_ERROR = 'PUT_ERROR',
}

export enum TopicInfoCode {
  POST_OK = 'POST_OK',
  FETCH_OK = 'FETCH_OK',
  INCONSISTENT_LONGNAME_SHORTNAME = 'INCONSISTENT_LONGNAME_SHORTNAME',
}

export enum TopicErrorCode {
  FETCH_ERROR = 'FETCH_ERROR',
  POST_ERROR = 'POST_ERROR',
  INCONSISTENT_LONGNAME_SHORTNAME = 'INCONSISTENT_LONGNAME_SHORTNAME',
}

export enum TopicContainerInfoCode {
  DELETE_TOPIC_CONTAINERS_HAD_ERRORS_REVERTING = 'DELETE_TOPIC_CONTAINERS_HAD_ERRORS_REVERTING',
  LANGUAGE_MISMATCH = 'LANGUAGE_MISMATCH',
  MINIMUM_ONE_RESSORT = 'MINIMUM_ONE_RESSORT',
  TOPIC_UNSAVED_CHANGES = 'TOPIC_UNSAVED_CHANGES',
  ID_MUST_BE_SET = 'ID_MUST_BE_SET',
  DELETE_OK = 'DELETE_OK',
  SAVE_OK = 'SAVE_OK',
  INVALID_ID = 'INVALID_ID',
  FAILED_UPDATES_REVERTED = 'FAILED_UPDATES_REVERTED',
  PUT_OK = 'PUT_OK',
}
export enum TopicContainerDropDownEmits {
  UPDATE_SEARCH_TEXT = 'updateSearchText',
  UPDATE_TOPIC_CANDIDATE = 'updateTopicCandidate',
  DELETE_TOPIC_FROM_CONTAINER = 'deleteTopicFromContainer',
  ADD_TOPIC_CANDIDATE_TO_TOPIC_CONTAINER = 'addTopicCandidateToTopicContainer',
}
export enum TopicContainerDropDownEvents {
  CandidateFirstItem = 'CANDIDATE_FIRST_ITEM',
  CandidateNextItem = 'CANDIDATE_NEXT_ITEM',
  CandidateLastItem = 'CANDIDATE_LAST_ITEM',
  CandidatePreviousItem = 'CANDIDATE_PREVIOUS_ITEM',
  CandidateNoItem = 'CANDIDATE_NO_ITEM',
  CandidateSpecificItem = 'CANDIDATE_SPECIFIC_ITEM',
}

export enum StafferRightsFlyByInfoCode {
  SETTINGS_WERE_RESET = 'SETTINGS_WERE_RESET',
  SETTINGS_WERE_SAVED = 'SETTINGS_WERE_SAVED',
  ADMIN_RIGHTS_REACTIVATED = 'ADMIN_RIGHTS_REACTIVATED',
}

export enum StafferRightsInfoCode {
  LOSE_OWN_ADMIN_RIGHTS_MSG = 'LOSE_OWN_ADMIN_RIGHTS_MSG',
  UNSAVED_CHANGES_MSG = 'UNSAVED_CHANGES_MSG',
}

export enum StafferRightsErrorCode {
  STAFFER_LIST_COULD_NOT_BE_FETCHED = 'STAFFER_LIST_COULD_NOT_BE_FETCHED',
  NO_USER_ID_ERROR = 'NO_USER_ID_ERROR',
  HISTORY_FETCH_FAILED = 'HISTORY_FETCH_FAILED',
  MISSING_SEARCH_STRING = 'MISSING_SEARCH_STRING',
  STAFFER_DATA_COULD_NOT_BE_FETCHED = 'STAFFER_DATA_COULD_NOT_BE_FETCHED',
  ROLE_LIST_FETCH_FAILED = 'ROLE_LIST_FETCH_FAILED',
  SAVE_FAILED = 'SAVE_FAILED',
}
export enum Roles {
  Produce = 'produce',
  Stafferdispo = 'stafferdispo',
  Assign = 'assign',
  Release = 'release',
  Publish = 'publish',
  Admin = 'admin',
  Videoadmin = 'videoadmin',
  Organisation = 'organisation',
}

export enum Languages {
  German = 'de',
  English = 'en',
  Spanish = 'es',
}
