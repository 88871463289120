import { default as column2ziu2Uz2dRMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/column.vue?macro=true";
import { default as deleted_45topics_45events59UFB0bvk8Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/deleted-topics-events.vue?macro=true";
import { default as indexVAxLkizASXMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/index.vue?macro=true";
import { default as product2XZ9SBGXbVMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/product.vue?macro=true";
import { default as release_45noteshOXy6Uno6MMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/release-notes.vue?macro=true";
import { default as rubricVaUrZcUzH5Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/rubric.vue?macro=true";
import { default as staffer_45dispotypeu0zWuPjSYWMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/staffer-dispotype.vue?macro=true";
import { default as indexYnXiEu7l89Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/staffer-rights/index.vue?macro=true";
import { default as team05gYiTpiLGMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/team.vue?macro=true";
import { default as topicqwVFVXfaQ6Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/topic.vue?macro=true";
import { default as indexwRZOMhs6diMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/index.vue?macro=true";
import { default as loginhDxSULS0r9Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/login.vue?macro=true";
import { default as logoutcs9eIf55MMMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/logout.vue?macro=true";
import { default as missing_45rightshIVSbNG17FMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/missing-rights.vue?macro=true";
import { default as calendarKZWMg0IwJdMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/calendar.vue?macro=true";
import { default as contact_45personlYCHLuKWUFMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/contact-person.vue?macro=true";
import { default as email5p3IjxbXcpMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/email.vue?macro=true";
import { default as location_45namesQGxO9I16F1Meta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/location-names.vue?macro=true";
import { default as organisations4ip7fM4DruMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/organisations.vue?macro=true";
import { default as dossiersOXI6sBXHngMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/dossiers.vue?macro=true";
import { default as indexH1LskVgq3YMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/index.vue?macro=true";
import { default as overviewsjy5KzPwFqRMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/overviews.vue?macro=true";
import { default as previews05q3gHrosEMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/previews.vue?macro=true";
import { default as staffer_45dataChxTa2ZHeNMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/staffer-data.vue?macro=true";
import { default as staffer_45dispositionsYhlu5pYAHQMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/staffer-dispositions.vue?macro=true";
import { default as team_45calendarOtjggSDQhaMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/team-calendar.vue?macro=true";
import { default as team_45setuplE2gg4DFIwMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/team-setup.vue?macro=true";
import { default as teamYaDhHoY7tUMeta } from "/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/team.vue?macro=true";
export default [
  {
    name: "admin-column",
    path: "/admin/column",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/column.vue")
  },
  {
    name: "admin-deleted-topics-events",
    path: "/admin/deleted-topics-events",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/deleted-topics-events.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/index.vue")
  },
  {
    name: "admin-product",
    path: "/admin/product",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/product.vue")
  },
  {
    name: "admin-release-notes",
    path: "/admin/release-notes",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/release-notes.vue")
  },
  {
    name: "admin-rubric",
    path: "/admin/rubric",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/rubric.vue")
  },
  {
    name: "admin-staffer-dispotype",
    path: "/admin/staffer-dispotype",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/staffer-dispotype.vue")
  },
  {
    name: "admin-staffer-rights",
    path: "/admin/staffer-rights",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/staffer-rights/index.vue")
  },
  {
    name: "admin-team",
    path: "/admin/team",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/team.vue")
  },
  {
    name: "admin-topic",
    path: "/admin/topic",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/admin/topic.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/logout.vue")
  },
  {
    name: "missing-rights",
    path: "/missing-rights",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/missing-rights.vue")
  },
  {
    name: "planning-calendar",
    path: "/planning/calendar",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/calendar.vue")
  },
  {
    name: "planning-data-care-contact-person",
    path: "/planning/data-care/contact-person",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/contact-person.vue")
  },
  {
    name: "planning-data-care-email",
    path: "/planning/data-care/email",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/email.vue")
  },
  {
    name: "planning-data-care-location-names",
    path: "/planning/data-care/location-names",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/location-names.vue")
  },
  {
    name: "planning-data-care-organisations",
    path: "/planning/data-care/organisations",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/data-care/organisations.vue")
  },
  {
    name: "planning-dossiers",
    path: "/planning/dossiers",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/dossiers.vue")
  },
  {
    name: "planning",
    path: "/planning",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/index.vue")
  },
  {
    name: "planning-overviews",
    path: "/planning/overviews",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/overviews.vue")
  },
  {
    name: "planning-previews",
    path: "/planning/previews",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/previews.vue")
  },
  {
    name: "planning-staffer-teams-staffer-data",
    path: "/planning/staffer-teams/staffer-data",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/staffer-data.vue")
  },
  {
    name: "planning-staffer-teams-staffer-dispositions",
    path: "/planning/staffer-teams/staffer-dispositions",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/staffer-dispositions.vue")
  },
  {
    name: "planning-staffer-teams-team-calendar",
    path: "/planning/staffer-teams/team-calendar",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/team-calendar.vue")
  },
  {
    name: "planning-staffer-teams-team-setup",
    path: "/planning/staffer-teams/team-setup",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/staffer-teams/team-setup.vue")
  },
  {
    name: "planning-team",
    path: "/planning/team",
    component: () => import("/builds/dpa-gmbh/produktionssysteme/zeus/Zeus-Vue-FE/app/pages/planning/team.vue")
  }
]